import mainStore from '@/stores/main-store';
import { storeToRefs } from 'pinia';

const useMainStore = () => {
    const { setAccessToken, setUsername, userRoles } = mainStore();

    const { accessToken, username, isMaster, isGPPUser } = storeToRefs(mainStore());

    return {
        accessToken,
        username,
        setAccessToken,
        setUsername,
        userRoles,
        isMaster,
        isGPPUser
    };
};

export default useMainStore;
